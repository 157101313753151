import dlink from '../';
import { getProperThumbnailSize, getVideoUrls, getProperMaxVideoSize } from '../../../../src/containers/Gallery/utils';
import { NUM_RETRIES_ON_ERROR } from '../../../../src/containers/Gallery/constants';

var _resize = [];
var imageSize;

function video(data, holder) {
	console.log("dlink video", data);
	var meta = data.metadata;
	var dynamicTitle;
	var template;

	// override

	if (dlink.code == 'XZJFuLZHB2zJ6b1CVJCTmCfv1iRPBBmwXr7') {
		data.country = 'sa';
	}

	template = HFN.renderTemplate('#dLinkVideo', {
		filename: meta.name,
		filesize: HFN.formatSize(meta.size)
	}).appendTo(holder);

	if ($.deparam.querystring().embed == 1) {
		template.addClass('embed');
	}

	//header title
	$('.header .filename').append(document.createTextNode(meta.name)).append(' <span>(' + HFN.formatSize(meta.size) + ')</span>');

	if (HFN.config.isMobile()) {
		if (!data.branding) {
			$('.header .filename').hide();
			// $('.header .bookmark').hide();
			// dynamic title
			dynamicTitle = dlink.dynamicTitle(meta);
			template.find('._title').append(dynamicTitle.template);
			_resize.push(dynamicTitle.resize);
		}

		dlink.addMobileButton(dlink.createShareButtonMobile());
		if (data.candownload ) {
			if (dlink._actionSheetDownloadItems().length !== 0) {
				dlink.addMobileButton(dlink.createDownloadButtonMobile());
			}
		}
		template.find('.button-area').remove();
	} else {
		if (data.candownload) {
			dlink.addDesktopButton(dlink.getShareHeaderIcon());
			dlink.addDesktopButton(dlink.getDownloadHeaderButton());
		}
	}
	_loadVideoPlayer(data, template.find('.player')[0]);

	$(window).on('resize', resize);
}

export default video;

/**********************************/
/* 			  Helpers			  */
/**********************************/

function _loadVideoPlayer(data, node, autoPlay = false, seek = 0, disableHLS = false) {
	var maxVideoSize = getProperMaxVideoSize(data.metadata.width || 0, data.metadata.height || 0);

	var videoData = {
		id: data.metadata.id,
		name: data.metadata.name,
		videoUrls: [],
		thumbUrl: "",
		thumbSize: "",//<width>x<height>
		thumbType: "image/jpeg",
		// subUrl: "",
		maxWidth: maxVideoSize.width,
		maxHeight: maxVideoSize.height,
		originalWidth: data.metadata.width || 0,
		originalHeight: data.metadata.height || 0,
		rotate: data.metadata.rotate,
		HLSpath: ""
	};

	var videoUrls = getVideoUrls(data.variants, false, disableHLS);
	
	if (videoUrls.length === 0) {
		showErrorLoadingVideo();
		return;
	}

	videoData.videoUrls = videoUrls;
	
	// Prepare thumb data
	var thumbWidth = getProperThumbnailSize(Math.min(videoData.maxWidth, Math.max(window.screen.availWidth, window.screen.availHeight)));
	var thumbHeight = getProperThumbnailSize(Math.min(videoData.maxHeight, Math.max(window.screen.availWidth, window.screen.availHeight)));
	videoData.thumbUrl = dlink.imageUrl(thumbWidth, thumbHeight);
	videoData.thumbSize = thumbWidth + "x" + thumbHeight;

	var vidstackMediaPlayer = {};
	var shownVideoSuccessTimeout = null;
	var videoContainerRemove = prc.VideoContainer(node, {
		ref: vidstackMediaPlayer,
		videoData,
		autoPlay,
		seek,
		noSwipeGesture: true,
		defaultDocumentTitle: document.title,
		setVideoLoading: toggleLoading,
		onPause: () => {
			// Something paused is previously played. Mark the video as healthy.
			delete data.refreshVideoLinksRetryCounter;
		},
		onPlay: () => {
			// Await 4 seconds before marking video as healthy.
			clearTimeout(shownVideoSuccessTimeout);
			shownVideoSuccessTimeout = setTimeout(() => {
				if (vidstackMediaPlayer && vidstackMediaPlayer.current && vidstackMediaPlayer.current.state && vidstackMediaPlayer.current.state.playing) {
					delete data.refreshVideoLinksRetryCounter;
				}
			}, 4000);
		},
		onError: ({autoPlay, mediaType, lastPlayingTime, disableHLSVideoRetries}) => {
			if (mediaType === "videoThumb") {
				// Broken video thumb. Keep showing the play button on black container.
				return;
			}
			
			// Reload video links.
			toggleLoading(true);
			
			videoContainerRemove();
			clearTimeout(shownVideoSuccessTimeout);

			HFN.apiMethod('getmediatranscodepublink', { code: dlink.code, linkpassword: dlink.linkpassword }, function (vlinksret) {
				var numRetriesOnError = NUM_RETRIES_ON_ERROR[mediaType];

				if (data.disableHLSVideoRetries) {
					numRetriesOnError = NUM_RETRIES_ON_ERROR["transcodedVideo"];// 1
				}

				if (data.refreshVideoLinksRetryCounter && data.refreshVideoLinksRetryCounter >= numRetriesOnError) {
					showErrorLoadingVideo();
					return;
				}

				data.disableHLSVideoRetries = data.disableHLSVideoRetries || disableHLSVideoRetries;
				
				// Increase retry counter.
				data.refreshVideoLinksRetryCounter = (data.refreshVideoLinksRetryCounter ? data.refreshVideoLinksRetryCounter : 0) + 1;
				data.variants = vlinksret.variants;

				// If we are on last allowed retry -> use the old way -> transcoded mp4 video variants.
				_loadVideoPlayer(data, node, autoPlay, lastPlayingTime, data.refreshVideoLinksRetryCounter === NUM_RETRIES_ON_ERROR["video"] || data.disableHLSVideoRetries);
			}, {
				// Let's make a new API call with fresh data.
				forceFresh: true,
				errorCallback: function (ret) {
					showErrorLoadingVideo();
				}
			})
		}
	});

	return videoData;
}

function toggleLoading(loading) {
	if(loading) {
		$(".player").addClass("loading");
	} else {
		$(".player").removeClass("loading");
	}
}

function showErrorLoadingVideo() {
	toggleLoading(false);
	$(".player").empty().append(HFN.renderTemplate('#dLinkVideoError'));
}

function resize() {
	new DelayedExecution(function () {
		_resize.forEach(function (eachResize) {
			eachResize();
		});
	}, 80, 'img-resize');
}
