// @flow
import $ from "jquery";
import { CATEGORY } from "@pcloud/web-utilities/dist/config/constants";
import ads2016 from './ads2016';
import openInApp, { canShow as canShowOpenInApp } from "./openInApp";
import { calcRelativeOffset, calcCoverHeight, getCoverPosition, getCoverSize, isSameLocation, getLocationFromUrl, retinaSrc, rcookie, getImageSize } from "../../lib/utils"
import * as views from "./views";
import apiConfig from "../../api/config";
import apiInit from "../../api/apiInit";
import { handleDisabledInfoModals } from "../../components/UploadManager/helpers";
import { URLMY } from "@pcloud/web-utilities/dist/config";
import hashManager from "../hashManager";

const asiaCodes = [
	'SA', 'ID', 'CN', 'MM', 'KW', 'TH', 'AE', 'TR', 'MY', 'OM', 'IN', 'TW',
	'EG', 'SG', 'YE', 'QA', 'PH', 'JO', 'IQ', 'VN', 'SY', 'IL', 'PK'
];

const code = $.deparam.querystring().code;
const linkpassword = $.deparam.querystring().linkpassword;
const showBranding = $.deparam.querystring().br !== "0";
let pinChecked = false;
const config = HFN.config;
const locationid = getLocationFromUrl() && getLocationFromUrl().id || HFN.US_LOCATIONID;
const dLink = {
  new: true,
	code: code,
	linkpassword: linkpassword,
	ispinned: false,
	views: {},
	holder: null,
	brandingImagesSize: {},
	loadData: function (data, callback) {
		var queue = [];
		var _self = this;
		var params = { code: code, iconformat: 'id', linkpassword: linkpassword };
		const token = rcookie("pcauth");

		if (token && token !== "0") {
			params.auth = token;
		}

		HFN.apiMethod('showpublink', params, function (ret) {
			data.metadata = ret.metadata;
			data.ownerispremium = ret.ownerispremium;
			data.ownerisbusiness = ret.ownerisbusiness;
			data.ownerisme = ret.ownerisme;
			HFN.config.dlinkownerisme = ret.ownerisme;
			data.linkid = ret.linkid;
			data.showads = ret.showads;
			data.linkpassword = linkpassword || ret.linkpassword;
			data.canupload = ret.canupload || false;
			data.candownload = ret.candownload || false;
			data.usercanupload = ret.usercanupload || false;
			data.showBranding = showBranding;

			console.log("showpublink ret: ", ret);

			if (code == 'XZbmXnZNqYXBmmqT5bH89AG5iwOQmnNA2ok') {
				data.ownerispremium = true;
				data.showads = true;
			}

			if (code == 'XZB8AnZAb3z52cIdvJasHaXvg9QimaotUry') {
				data.overtraffic = false;
				data.showads = true;
			}

			if (code == 'XZh5b7ZuguF7Twiz1X4q4dXnVF0K8sBM0dy') {
				data.ownerispremium = false;
			}

			if (code == "XZkuSIZrATuCQlLGyHoWHOMYHl3TVrYhkM7") {
				data.overtraffic = true;
			}

			if (ret.metadata.category == HFN.CATEGORY.VIDEO) {
				queue.push(HFN.apiMethod('getmediatranscodepublink', { code: code, linkpassword: linkpassword }, function (vlinksret) {
					data.variants = vlinksret.variants;
				}, {
					errorCallback: function (ret) {
						if ([7006, 7014].indexOf(ret.result) != -1) {
							data.overtraffic = true;
						} else if (ret.result != 7005) {
							HFN.message(ret.error, 'error');
						}
					}
				}));
			}

			if (data.metadata.category == HFN.CATEGORY.DOCUMENT) {
				var method = 'getpublinkdownload';
				if (HFN.docPreview.canPreview(data.metadata))
					method = 'getpublinkpreviewdownload';

				queue.push(HFN.apiMethod(method, { code: code, linkpassword: linkpassword, hashCache: data.metadata.hash }, function (previewRet) {
					data.previewlink = HFN.prepUrl(previewRet);
				}, {
					errorCallback: function (previewRet) {
						if (previewRet.result != 7005 && previewRet.error)
							HFN.message(previewRet.error, 'error');
					}
				}));
			}

			if (data.metadata.category == HFN.CATEGORY.AUDIO) {
				var method = data.candownload ? 'getpublinkdownload' : 'getpubaudiolink'


				queue.push(HFN.apiMethod(method, { code: code, linkpassword: linkpassword }, function (audioRet) {
					data.audiolink = HFN.prepUrl(audioRet);
				}, {
					errorCallback: function (audioRet) {
						if (audioRet.result != 7005)
							HFN.message(audioRet.error, 'error');
					}
				}));
			}

			// file or folder
			//if (code == 'XZidPLZow1MrRAcJJuCra1cabPTKVxtfBO7') {
			if (code == 'kZ0dFZtQdcAHlqTj59WVXlc9QivJ0A7SnX') {
				data.overtraffic = true;
				data.downloadlink = null;
				data.ownerispremium = false;
			} else if (ret.overtraffic) {
				data.overtraffic = true;
				data.downloadlink = null;
				if (data.ownerispremium == undefined) {
					data.ownerispremium = ret.ownerispremium;
				}
			} else {
				if (data.metadata.isfolder) {
					console.log("getpubzip linkpassword", linkpassword)
					data.downloadlink = HFN.apiMethodUrl(null, 'getpubzip', { code: code, linkpassword: linkpassword });
				} else {
					var method = data.candownload ? 'getpublinkdownload' : 'getpubaudiolink'
					queue.push(HFN.apiMethod(method, { code: code, linkpassword: linkpassword, forcedownload: 1 }, function (downloadRet) {
						data.downloadlink = HFN.prepUrl(downloadRet);
						data.code = code;
					}, {
						errorCallback: function (downloadRet) {
							if (downloadRet.result == 7005) {
								data.overtraffic = true;
								data.downloadlink = null;
								data.ownerispremium = false;
							}
						}
					}));
				}
			}

			// queue.push(HFN.apiMethod('getip', {}, function (ret) {
			// 	data.country = ret.country;
			// 	data.language = ret.country;
			// }));

			var langFromUrl = HFN.getSearch("lang") || "";
			data.country = langFromUrl;
			data.language = langFromUrl;
			console.log("Language 1", data.language);

			pCloudIntl.updateLang(data.language);

			if (ret.branding) {
				data.branding = ret.branding;
			}

			/*
			data.branding =  {
				"bgcolor": "ff0000",
				"logo": {
					"expires": "Tue, 29 Mar 2016 14:02:28 +0000",
					"path": "\/DLZBolQrZZkZXZeUouq7Z3VZZbFkZZnOZQkZakZaZ5L1SNDQdM64DPcJH1Jlzfbm5Qk9V\/logo.png",
					"hash": 4069884332877721245,
					"hosts": [
						"p-sf1.pcloud.com",
						"c138.pcloud.com"
					]
				}
			};
			*/

			$.when.apply($, queue).then(callback.bind());
		}, {
			errorCallback: function (ret) {
				data.metadata = false;
				data.result = ret.result;
				data.error = ret.error;

				if (ret.result === 2331) {
					// local redirect url (data region relocation) 
					window.location = ret.moved;
				} else if (ret.result === 4008) {
					data.error = __("error_4008", "You can't access the content of this link temporarily. Try again later");
				}
				HFN.apiMethod('getip', {}, function (ret) {
					data.country = ret.country;
					callback();
				});
			}
		});
	},

	init: function(data) {
		apiInit();
		if (!data.candownload) {
			// hide context menu
			document.addEventListener("contextmenu", event => event.preventDefault());
			// disable pint option
			var head = document.head || document.getElementsByTagName("head")[0];
			var style = document.createElement("style");
			var css = "* { display: none; }";
			head.appendChild(style);
			style.type = "text/css";
			style.media = "print";
			if (style.styleSheet) {
				// This is required for IE8 and below.
				style.styleSheet.cssText = css;
			} else {
				style.appendChild(document.createTextNode(css));
			}

		}

		const disableBodyScrollOnModal = (e) => {
			var root = document.getElementsByTagName( 'html' )[0];
			if (
				root.classList.contains('dlink') &&
				root.classList.contains('g-modal-open') &&
				!e.target.closest('div[class^="Modal__ModalHolder"]')
			) {
				e.preventDefault();
				return false;
			}
		}

		const wheelOpt = { passive: false };
		const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
		window.addEventListener('DOMMouseScroll', disableBodyScrollOnModal, false);
		window.addEventListener(wheelEvent, disableBodyScrollOnModal, wheelOpt);

		console.log('init', data)
		if (data.metadata) {
			if (!data.metadata.contents) {
				data.metadata.contents = []
			}
			HFN.cacheTree(data.metadata, 'public', HFN.data.fflookup);
		}

		this.data = data;
		console.log("Language 2", this.data.language);

		pCloudIntl.setLang(this.data.language);

		var hasBranding = Boolean(this.data.branding)
		if (hasBranding && showBranding) {
			var imgs = [];
			var hasLogo = Boolean(this.data.branding.logo1x);
			var hasMobile = Boolean(data.branding.covermobile);
			var imgUrls = [];
			var self = this;

			if (hasLogo) {
				if ($.browser.mobile) {
					if (this.data.branding.logomobile) {
						imgUrls.push(this.data.branding.logomobile);
					}
				} else if (window.devicePixelRatio > 1) {
					imgUrls.push(this.data.branding.logo1x);
					imgUrls.push(this.data.branding.logo2x);
				} else {
					imgUrls.push(this.data.branding.logo1x);
				}
			}

			if (hasMobile && ($.browser.mobile || window.screen.width <= 1080)) {
				imgUrls.push(this.data.branding.covermobile);
			} else if (window.screen.width <= 1366 && data.branding.coversmall) {
				imgUrls.push(this.data.branding.coversmall);
			} else if (window.screen.width > 1366) {
				let cover = data.branding.coverbig || data.branding.coversmall;
				if (cover) {
					imgUrls.push(cover);
				}
			}

			const promises = imgUrls.map(img => getImageSize(img).then(function (img) {
				if (!img) {
					return;
				}
				self.brandingImagesSize[img.path] = {
					width: img.width,
					height: img.height
				};
			}));

			Promise.all(promises).then(() => {
				this._setupLayout();
				this._compat();
				this._go();
			});
		} else {
			this._setupLayout();
			this._compat();
			this._go();
		}

		if (data.dwltag) {
			setcookie("dwltag", data.dwltag, 1);
		}
		
		HFN.initCrashlytics(HFN.apiMethod);

		if (rcookie("pcauth") && !data.ownerisbusiness) {
			HFN.initAnalytics();
			HFN.initFacebook();
			HFN.initTwitter();
			HFN.initTiktokTracking();
		} else if (rcookie('cookieconsent') && !data.ownerisbusiness) {
			HFN.initTracking([
				{
					type: HFN.config.GA_FLAG, 
					onStart: HFN.initAnalytics
				}, 
				{
					type: HFN.config.FBQ_FLAG,
					onStart: function() {
						HFN.initFacebook();
						HFN.initTwitter();
						HFN.initTiktokTracking();
					}
				}
			]);
		}
	},

	setCookieConsent: function () {
		var self = this;
		$('#megawrap').append("<div class='cookie_consent_holder'></div>");

		if (!rcookie('cookieconsent')) {
			this.removeCookieConsent = prc.CookieConsent($('.cookie_consent_holder')[0], {
				initAnalyticsTracking: HFN.initAnalytics,
				initFacebookTracking: HFN.initFacebook,
				initTiktokTracking: HFN.initTiktokTracking,
				initTwitterTracking: HFN.initTwitter,
				onAgreed: () => {
					if (self.removeCookieConsent) {
						self.removeCookieConsent();
					}
					$(".cookie_consent_holder").closest('body').removeClass('cookie_consent');
				}
			});
		}
	},

	refresh: function () {
		window.location.reload();
	},

	_userPromise: null,

	getUser: function(callback) {
		this._userPromise.done(function(user) {
			if (!obLength(user)) {
				user = false;
			}
			callback(user);
		});
	},

	// compat method to equalize the publink to a normal my environment
	_compat: function() {
		var self = this;

		this._userPromise = $.Deferred();
		const token = rcookie("pcauth");

		if (token && token !== "0") {
			HFN.apiMethod("userinfo", { auth: rcookie('pcauth') }, ret => {
				self._userPromise.resolve(ret);

				HFN.config.user = $.extend({}, ret);
				HFN.config.auth = rcookie('pcauth');
			}, { errorCallback: () => { self._userPromise.resolve({}); }});
		} else {
			self._userPromise.resolve({});

      //HFN.initPushNotifications();
      if (!this.data.ownerisbusiness) {
        this.setCookieConsent();
      }
		}

		/*
		HFN.initUser(rcookie('pcauth') || '', function(user) {

			self._userPromise.resolve(HFN.config.user);

			if (!HFN.config.auth) {
				//$('.logo > a > span').removeClass('pcloud-logo').addClass('pcloud-logo-my')
				//$('.logo > a').attr('href', '//www.pcloud.com');
			}
		});
		*/

		if (HFN.config.isMobile()) {
			$.mobile.orientationChangeEnabled = false;
			$(window).on('orientationchange', function () {
				$(window).trigger('onmobileresize');
				$(window).on('resize.tmp', function() {
					$(window).trigger('onmobileresize');
					$(window).off('resize.tmp');
				});
			});
		}

		//
		HFN.init({
			type: $.browser.mobile ? 'mobile' : 'site',
			dlink: true
		});
		console.log("Language 3", this.data.language);

		// language
		pCloudIntl.init({
			language: this.data.language
		});

		// setup branding based on owner ot public link
		if (this.data.branding) {
			HFN.branding.init(this.data.branding);
		}

	},
	initBookmark: function() {
		console.info('initBookmark');
		if ($('.edit-bookmark-tooltip').length) {
			return;
		}
		var bookmark = $(".bookmark");
		var node = $("<div class='edit-bookmark-tooltip' div/>").appendTo(".bookmark")[0];
		var dataName = this.data.metadata.name;


		if (
			HFN.config.isMobile() &&
			!this.data.metadata.isfolder &&
			this.data.metadata.audiolink &&
			this.data.metadata.category === HFN.CATEGORY.AUDIO
		) {
			// Disable bookmark for one page mobile audio player
			bookmark.css({ display: "none" });
		} else {
			bookmark.css({ display: "flex" });
		}

		prc.EditBookmarkTooltip(node, {
			code,
			name: dataName,
			locationId: locationid,
			holderObject: document.createElement("div")
		});
	},
	_setupLayout: function() {
		console.log("PUB data: ", this.data)

		var self = this;
		var hasBranding = Boolean(this.data.branding)
		var layout = '#desktopLayout';
		var headline = "";
		var description = "";
		var imageSrc = "";
		var coverSrc = "";
		var coverPosition = 0;
		var coverSize = "100%";
		var hideRibbon = this.data.ownerisbusiness || this.data.ownerispremium || hasBranding;

		if (!hideRibbon) {
			setTimeout(function () {
				// Show promo modal and ribbon
				HFN.initShowPromoOffer();
			}, 500);
		}

		// var showBranding = this.data.branding;

		if (hasBranding && showBranding) {
			headline = this.data.branding.header;
			description = this.data.branding.description;
			var hasLogo = Boolean(this.data.branding.logo1x);
			var hasCover = Boolean(this.data.branding.coversmall);
			var coverColor = hasBranding && this.data.branding.covercolor ? this.data.branding.covercolor  : false;
			var coverGradient = hasBranding && this.data.branding.covergradient ? this.data.branding.covergradient : false;

			if (hasLogo) {
				if ($.browser.mobile) {
					if (this.data.branding.logomobile) {
						imageSrc = HFN.prepUrl(this.data.branding.logomobile);
					}
				} else if (window.devicePixelRatio > 1) {
					imageSrc = HFN.prepUrl(this.data.branding.logo2x);
				} else {
					imageSrc = HFN.prepUrl(this.data.branding.logo1x);
				}
			}

			function initCoverData(data) {
				var currentWidth = hasBranding && data.branding.coverwidth ? window.innerWidth : 1;
				var originalWidth = data.branding.coverwidth || 1;
				var coverOffset = hasBranding && data.branding.coveroffsety ? calcRelativeOffset(currentWidth, originalWidth, data.branding.coveroffsety) : 0;
				var coverImageHeight;
				var coverContainerHeight = $.browser.mobile ? 180 : 300;
				var hasMobile = Boolean(data.branding.covermobile);

				if (hasMobile && ($.browser.mobile || window.screen.width <= 1080)) {
					coverSrc = HFN.prepUrl(data.branding.covermobile);
					coverImageHeight = calcCoverHeight(self.getBrandingImageSize(data.branding.covermobile, 'height'), self.getBrandingImageSize(data.branding.covermobile, 'width'));
				} else if (window.screen.width <= 1366 && data.branding.coversmall) {
					coverSrc = HFN.prepUrl(data.branding.coversmall);
					coverImageHeight = calcCoverHeight(self.getBrandingImageSize(data.branding.coversmall, 'height'), self.getBrandingImageSize(data.branding.coversmall, 'width'));
				} else if (window.screen.width > 1366) {
					let cover = data.branding.coverbig || data.branding.coversmall;
					if (cover) {
						coverSrc = HFN.prepUrl(cover);
						coverImageHeight = calcCoverHeight(self.getBrandingImageSize(cover, 'height'), self.getBrandingImageSize(cover, 'width'));
					}
				}

				if (coverImageHeight) {
					coverPosition = getCoverPosition(coverOffset, coverImageHeight, coverContainerHeight);
					coverSize  = getCoverSize(coverOffset, coverImageHeight, coverContainerHeight);
				}
			}

			function repositionCover(coverSrc, coverPosition, coverSize) {
				if (!$.browser.mobile) {
					$('.branding-header').css({
						"background-image": "url(" + coverSrc + ")",
						"background-size": coverSize,
						"background-position": coverPosition === "bottom" ? coverPosition : "0px " + coverPosition + "px"
					});
				} else {
					$('#branding .cover-photo').css({
						"background-image": "url(" + coverSrc + ")",
						"background-size": coverSize,
						"background-position": coverPosition === "bottom" ? coverPosition : "0px " + coverPosition + "px"
					});
				}
			}

			if (hasCover) {
				var data = this.data;
				initCoverData(data);
				$( window ).resize(function() {
					initCoverData(data);
					repositionCover(coverSrc, coverPosition, coverSize);
				});
			}
		}

		if ($.browser.mobile) {
			layout = '#mobileLayout';
			$('html').addClass('mobile');
			$('link.files').after('<link rel="stylesheet" href="/ZCDNURL/ZREPLACETHISWITHVERSION/css/files_mob.css" class="files_mob">');
		}

		$('html').addClass('dlink');
		$('#megawrap').empty();

		try {
			this.holder = HFN.renderTemplate(layout, {
				headline: headline,
				description: description,
				imageSrc: imageSrc,
				powered_by: __("powered_by", "Powered by pCloud")
			}).appendTo('#megawrap').find('#dl_content');
		} catch (error) {
			console.error("Loading preview error", error, error.message);
		}

		window.addEventListener("error", (error) => {
			console.error("THIS IS AN ERROR FROM EVENT LISTENER", error, error.message);
		});


		console.log("title-image src:", imageSrc)
		console.log("headline:", headline)
		console.log("description:", description)

		if (hasBranding && showBranding) {
			if (!imageSrc) {
				$('.branding-header .title-image').remove();
			} else {
				var imgWidth = $.browser.mobile ? (this.getBrandingImageSize(this.data.branding.logomobile, 'width') / 2) : this.getBrandingImageSize(this.data.branding.logo1x, 'width');
				var imgHeight = $.browser.mobile ? (this.getBrandingImageSize(this.data.branding.logomobile, 'height') / 2) : this.getBrandingImageSize(this.data.branding.logo1x, 'height');

				if ($.browser.mobile && imgWidth > 300) {
					var imgWidthMobile = $(".branding-header").width();
					var imgHeightMobile = imgHeight / (imgWidth / imgWidthMobile);
					$('.branding-header .title-image').css({
						"width": imgWidthMobile + "px",
						"height": imgHeightMobile,
						"max-width": imgWidth,
						"max-height": imgHeight,
					});
				} else {
					$('.branding-header .title-image').css({
						"width": imgWidth,
						"height": imgHeight,
						"border": "3px solid #fff",
						"border-radius": "3px"
					});
				}
			}

			if (!$.browser.mobile) {
				$(".branding-header").css({
					"height": 300,
					"box-sizing": "border-box"
				});
			}

			if (coverSrc && !$.browser.mobile) {
				$('.branding-header').css({
					"background-image": "url(" + coverSrc + ")",
					"background-size": coverSize,
					"background-repeat": "no-repeat",
					"background-position": coverPosition === "bottom" ? coverPosition : "0px " + coverPosition + "px"
				});
				$('.branding-header .texts').css({
					color: "#fff"
				})

				if (headline || description) {
					$('.branding-header .gradient-bg').css({
						display: "inline-block"
					})
				}

				if (!imageSrc) {
					$('.branding-header .texts').css({
						position: "absolute",
						left: "0px",
						bottom: "36px"
					})
				}
			} else if (coverSrc && $.browser.mobile) {
				$(".branding-header").css({
					"height": "initial"
				});

				$('#branding .cover-photo').css({
					"display": "inline-block",
					"background-image": "url(" + coverSrc + ")",
					"background-size": coverSize,
					"background-repeat": "no-repeat",
					"background-position": coverPosition === "bottom" ? coverPosition : "0px " + coverPosition + "px"
				});

				if (imageSrc) {
					$(".branding-header").css({
						"padding-top": "120px",
						"min-height": "180px"
					})
				} else {
					$(".branding-header").css({
						"padding-top": $('#branding .cover-photo').outerHeight()
					})
					$(".branding-header .texts").css({
						"margin-top": "30px"
					})
				}

			} else if ((coverColor || coverGradient)&& !$.browser.mobile) {
				if (coverColor) {
					$('.branding-header').css({
						"background-color": "#" + coverColor
					});
				} else if (coverGradient) {
					coverGradient = coverGradient.split(",");
					$('.branding-header').css({
						"background": "linear-gradient(to right, #" + coverGradient[0] + ", #" + coverGradient[1] + ")"
					});
				}

				$('.branding-header .texts').css({
					color: "#fff"
				})

			} else if ((coverColor || coverGradient) && $.browser.mobile) {
				if (imageSrc) {
					if (coverColor) {
						$('.cover-photo').css({
							"display": "inline-block",
							"background-color": "#" + coverColor
						});
					} else if (coverGradient) {
						coverGradient = coverGradient.split(",");
						$('.cover-photo').css({
							"display": "inline-block",
							"background": "linear-gradient(to right, #" + coverGradient[0] + ", #" + coverGradient[1] + ")"
						});
					}

					$(".branding-header").css({
						"padding-top": "120px",
						"min-height": "180px"
					})
				} else {
					if (coverColor) {
						$(".branding-header").css({
							"background-color": "#" + coverColor
						})
					} else if (coverGradient) {
						coverGradient = coverGradient.split(",");
						$('.branding-header').css({
							"background": "linear-gradient(to right, #" + coverGradient[0] + ", #" + coverGradient[1] + ")"
						});
					}

					$('.branding-header .texts').css({
						color: "#fff"
					})
				}
			} else if (!(coverColor || coverSrc || coverGradient) && $.browser.mobile && (headline || description)) {
				$(".branding-header").css({
					"height": "initial"
				});
			}

			if (!headline) {
				$('.branding-header .headline').remove();
			} else {
				if ($.browser.mobile) {
					$('.branding-header .title-image').css({ "margin-bottom": "26px" });
				} else {
					$('.branding-header .title-image').css({ "margin-bottom": "36px" });
				}
			}

			if (imageSrc && !headline && description) {
				$('.branding-header .title-image').css({ "margin-bottom": "36px" });
			}

			if (!description) {
				$('.branding-header .description').remove();
			} else {
				$('.branding-header .headline').css({ "margin-bottom": "8px" });
			}

			$("#basic").remove();
			$("#branding > .header-wrap").css({
				position: "absolute",
				top: $(".branding-header").outerHeight()
			});

			var header = $("#branding > .header-wrap").offset().top;

			$(window).scroll(function() {
				//close dropdown menu
				dropDown.close();

				var currentScroll = $(window).scrollTop();

				if (currentScroll >= header) {
					$("#branding > .header-wrap").css({
						position: "fixed",
						top: "0",
						left: "0"
					});
					$(".dl-sidebar").css({
						top: $(".header-wrap").outerHeight() + $(".headerunder").outerHeight()
					})
	
				} else {
					$("#branding > .header-wrap").css({
						position: "absolute",
						top: $(".branding-header").outerHeight()
					});

					$(".dl-sidebar").css({
						top: $(".branding-header").outerHeight() + $(".header-wrap").outerHeight()
					})
				}
			})
		} else {
			if (!showBranding) {
				$("#basic").remove();
				$("#branding > .branding-header").css({
					display: "none"
				})
				$("#branding > .header-wrap").css({
					position: "absolute"
				});
			} else {
				$("#branding").remove();
			}
		}

		$(".logo-place").find("a").prop("href", URLMY);

		// Add bookmark
		// Bookmark is now inited in initUser

		// Add services button

		this.addDesktopButton(this.getServicesButton());
	},

	_go: function(view) {
		var self = this;

		if (view) {
			this._view = view;
		} else {
			this._view = this._template();
		}

			console.log(views);
			console.log(this._view);

		views[this._view](this.data, this.holder);
		this._postView();

		//this.yellowLine.show();
		ads2016.show(this.data);
		// this.initSidebar();

		setcookie("refcode", this.code, 0.83);
		setcookie("publinkcode", this.code, 0.83);

		/*
		this.ads2016.willShowAd(function(showAd) {
			if (!showAd) {
				self.inviteToLogin();
			}
		});
		*/
	},

	_template: function() {
		var view = 'generic';
		var data = this.data;
		var meta = data.metadata;
		var viewCategoryMap = {};
		var blobid;

		viewCategoryMap[CATEGORY.IMAGE] = 'image';
		viewCategoryMap[CATEGORY.VIDEO] = 'video';
		viewCategoryMap[CATEGORY.AUDIO] = 'audio';
		viewCategoryMap[CATEGORY.DOCUMENT] = 'documentview';

		// hide/show document preview for mobile
		// if ($.browser.mobile)
		// 	delete viewCategoryMap[CATEGORY.DOCUMENT];

		console.log('data', data);

		if (data.result === 4008) {
			data.error = __("error_4008", "You can't access the content of this link temporarily. Try again later");
		}

		if (data.error) {
			if ([2258, 1125].indexOf(data.result) != -1) {
				view = 'password';
			} else {
				view = 'error';
			}
		} else if (data.overtraffic) {
			view = 'overtraffic';
		} else if (meta.isfolder) {
			view = 'folder';
		} else if (meta.category in viewCategoryMap) {
			view = viewCategoryMap[meta.category];
		}

		if (view === "documentview" && !HFN.docPreview.canPreview(meta) && fileext(meta.name).toLowerCase() !== "pdf") {
		  view = "generic";
		}

		if (isAsia() && isVideo(view) && data.showads) {
			view = 'overtraffic';
		}

		return view;
	},

	go: function(view) {
		this._setupLayout();
		this._compat();
		this._go(view);
	},

	getDownloadButton: function() {
		var self = this;
		return HFN.renderTemplate('#downloadButton')
			.on('click', this._actionDownloadFile.bind(this));
	},

	_postView: function() {
		var isCopynow = $.deparam.querystring().copynow;
		var self = this;

		if (isCopynow) {
			self.getUser(function(user) {
				self._actionCopyToCloud();
			});
		} else {
			this._gaEvent('show');
		}

		if ($.browser.mobile && this._view === "documentview") {
			$("footer").hide();
		}

		var isPDF = this.data && this.data.metadata && this.data.metadata.category === CATEGORY.DOCUMENT && fileext(this.data.metadata.name).toLowerCase() === "pdf";

		if (canShowOpenInApp() && isSameLocation() && this.data.candownload && !isPDF) {
			openInApp(this.code);
		}
	},

	_isManyPhotos: function(meta) {
		var cnt = 0;
		var n = 0;
		var eachFile = function eachFile(meta) {
			if (meta.category == HFN.CATEGORY.IMAGE)
				cnt++;
			n++;

			if (meta.isfolder) {
				for (var i = 0; i < meta.contents.length; ++i) {
					eachFile(meta.contents[i]);
				}
			}
		}

		eachFile(meta);
		n--;

		return cnt > n / 2;
	},

	_gaEvent: function (type) {
		var eventCategory = (config.isMobile() ?  'Mobile' : 'Desktop') + ' DLink ';
		var view = this._view;
		var data = this.data;
		var meta = data.metadata;

		if (this._isManyPhotos(meta)) {
			view = 'photos';
		}

		if (view == 'overtraffic') {
			view = view + ' - ' + (data.ownerispremium ? 'premium' : 'free');
		}
	},

	getDisabledDownloadButton: function () {
		return this.getDownloadButton().off('click').addClass('disabled');
	},

	getSaveButton: function() {
		var self = this, meta = this.data.metadata;
		return HFN.renderTemplate('#saveButton')
			.on('click', this._actionCopyToCloud.bind(this));
	},

	_actionCopyToCloud: function (data, modalContainer) {
	    if (!data) {
		    data = this.data
	    }
		var dLinkMeta = HFN.dLink.data.metadata;
		
		var meta = data.metadata || data.metaData;
		if (dLinkMeta) {
			meta = dLinkMeta;
		}
		
		var self = this;
		var refId = 558;
		var clearAfterMe = function () { fs.reset(); };
		var copy = function () {
			if (typeof gtag === "function") {
				gtag("event", "shared_link_click", {
					action: "save to pCloud",
					location: "header",
					user: HFN.config.auth ? 1 : 0
				})
			}

			if (meta && meta.ismine) {
				HFN.message(__('File or folder already exists.'), 'error');
				return
			}

			if (!HFN.config.user.emailverified && data.overtraffic) {
				HFN.message(__('verify_account_check_email_info', 'You need verified account for this action. Check your e-mail for more info.'), 'error');
				self.sendVerifyMail();
				return;
			}

			console.log('calling copyToCloud: code, linkpassword, meta', code, data.linkpassword, meta);

			HFN.copyToCloud({
				code: code,
				linkpassword: data.linkpassword,
				toCopy: meta,
				onDone: function () {}
			}, modalContainer);
		}

		if (HFN.config.auth) {
			copy();
		} else {
			if (this._view == 'overtraffic') {
				refId = 564;
			}

			setcookie('ref', refId, 0);

			if (this._view == 'overtraffic') {
				location.href =
					'https://www.pcloud.com/cloud-storage-pricing-plans.html?custombox=on&returnurl=' +
					encodeURIComponent(this._location() + '&copynow=1') +
					'&ref=' + refId;
			} else {
				HFN.initSaveFileLoginRegModal(copy, modalContainer);
				//HFN.initSurpriseLoginRegModal('register', copy);
			}
		}
	},

	premiumOnlyUrl: function(url) {

	},

	sendVerifyMail: function () {
		if (rcookie('_verifymail')) {
			return;
		}

		HFN.apiMethod('sendverificationemail', {}, function() {}, { errorCallback: function() {} });
		setcookie('_verifymail', 1, 0);
	},
	getDownloadHeaderButton: function (outheader) {
		var self = this;
		var meta = this.data.metadata;
		var btn;
		var actions;
		var options;

		options = {
			holderClass: 'mn-share'
		}

		if (outheader) {
			options.holderClass = 'mn-share';
		}

		btn = $('<a href="javascript:;"></a>')
			.text(__('Download', 'Download'))
			.addClass("download_button")

		actions = [
			[meta.isfolder ? __('save_to_device_zip', 'Download as ZIP') : __('direct_download', 'Direct download'), '', false, "download", { action: 'download', subtext: __('save_to_device_zip_description', 'Save directly to your device')}]
		];
		if (isSameLocation()) {
			actions.unshift([__('save_to_pcloud', 'Save to pCloud'), '', false, "save-to-pcloud", { action: 'save', subtext: __('save_to_pcloud_description', 'Save directly to pCloud for free') }])
		}

		dropDown.bindList(actions, btn, {
			direction: dropDown.DIR_LEFT,
			eventTrigger: 'click',
			eventClose: 'click',
			position: 'fixed',
			showTip: true,
			holderClass: options.holderClass,
			overwriteTip: {
				right: 9
			},
			buildCell: function (item, el) {
				if (item[3] === 'save-to-pcloud' && !isSameLocation()) {
					return;
				}
				console.log("DOWNLOAD ITEM", item, dropDown.N_SUB);
		        var a = $('<a class="download-menu-item"></a>')
		            .attr('href', 'javascript:void(0);')
		            .attr('data-action', item[4].action)
		            .append(
		                $('<li>')
							//.append('<img src="/ZCDNURL'+ item[3] +'" width="15" height="15">'
							.append(
								$('<div class="info-wrap"></div>')
									.append($('<div class="option-title"></div>').text(item[dropDown.N_TEXT] + ( item[dropDown.N_SUB] && 0 ? ' (sub)': '' ) ))
									.append($('<div class="sub-text"></div>').text(item[4].subtext))
							)
							.append('<div class="' + item[3] + '">')
		            )
		            .on('click', function (e) {
		            	e.preventDefault();
									e.stopPropagation();
									if ($(this).data("action") == "download") {
										self._actionDownloadFile();
									} else if ($(this).data("action") == "save") {
										self._actionCopyToCloud();
									}

		            	dropDown.resetTo(0);
		            })
		            .appendTo(el);

		        return a;
			}
		});

		return btn;
	},

	getShareHeaderIcon: function () {
		var self = this;
		var icon;
		var actions;
		var list;

		icon = $('<a href="javascript:;"></a>')
			.text(__("Share"))
			.addClass("share_button")

		list = [
			//['fb', 'Facebook'],
			//['tw', 'Twitter'],
			//['gp', 'Google Plus']
			['fb', '<div>Facebook</div><div class="publink-sprite fb"></div>'],
			['tw', '<div>Twitter</div><div class="publink-sprite tw"></div>'],
		];

		if (copySupported()) {
			//list.unshift(['copy', __('Copy link')]);
			list.unshift(['copy', '<div>'+i18n.get('Copy link')+'</div>'+'<div class="publink-sprite link copy-this-link"></div>']);
			attachCopy('.sharer-copy', this._location(), function (e) {
				HFN.message(__('Link copied.'));
			});
		}

		dropDown.bindList(list, icon, {
			direction: dropDown.DIR_LEFT,
			childDirection: dropDown.DIR_LEFT,
			eventTrigger: 'click',
			eventClose: 'click',
			position: 'fixed',
			holderClass: 'mnnew share',
			showTip: true,
			holderClass: 'mn-share',
			overwriteTip: {
				right: 8
			},
			buildHolder: function (obj) {
				// obj/*.addClass('mn-blue')*/.css('z-index', 101);
			},
			buildCell: function (item, el) {

				var litem = $('<a class="' + item[0] + ' sharer-'+item[0]+'" href="javascript:void(0)"><li>'+item[1]+'</li></a>')
					.on('click', function(e){
						console.log(item[0]);
						console.log('ccc', e);
						if (typeof gtag === "function") {
							if (item[0] == 'copy') {
								gtag("event", "shared_link_click", {
									action: "copy link",
									category: "share",
									type: "copy",
									location: "header",
									user: HFN.config.auth ? 1 : 0
								});
							} else if (item[0] == 'fb') {
								gtag("event", "shared_link_click", {
									action: `initaite facebook share`,
									category: "share",
									type: "social share",
									location: "header",
									user: HFN.config.auth ? 1 : 0
								})
							} else if (item[0] == 'tw') {
								gtag("event", "shared_link_click", {
									action: `initaite twitter share`,
									category: "share",
									type: "social share",
									location: "header",
									user: HFN.config.auth ? 1 : 0
								})
							}
						}
						if (item[0] == 'copy') {
							dropDown.resetTo(0);
						} else {
							// share
							e.preventDefault();
							e.stopPropagation();
							self.desktopShare(item[0]);
						}
					}).appendTo(el);

				/*
				if (item[0] == 'copy') {
					$('#sharer-cp').zclip({
						path: '/swf/ZeroClipboard.swf',
						copy: "https://" + window.location.host + window.location.pathname + "?code=" + self.code
					});
				}
				*/

				return litem;
			}
		});

		return icon;
	},

	getServicesButton: function () {
		var wrapper = $('<span class="services-button"></span>');
		prc.PCloudServicesDropdown(wrapper[0], {});
		return wrapper;
	},

	addDesktopButton: function (button) {
		$('.header .header-links').prepend(button);
	},

	getMobileSaveButton: function () {
		var self = this, meta = this.data.metadata;
		return HFN.renderTemplate('#saveButton')
			.on('click', this._actionCopyToCloud.bind(this));
	},

	getDesktopButtons: function() {
		return [this.getSaveButton(), this.getDownloadButton()];
	},

	clearMobileButtons: function () {
		$('.header .buttons').empty();
	},

	addMobileButton: function(button) {
		$('.header .buttons').append(button);
	},

	addHeaderFilename: function(value) {
		$('.header .name-wrapper .filename').text(value);
	},

	_initiateLogin: function() {
		if ($.browser.mobile) {
			location.href =
				'/#page=login' +
				'&memo=' + encodeURIComponent('Login to save') +
				'&returl='+ encodeURIComponent(this._location() + '&copynow=1');
		} else {

		}
	},

	_actionDownloadFile: function(data) {
	  	if (!data) {
			  data = this.data;
		}
		var self = this;
		var isLogged = HFN.config.auth;
		var isOwnerPremium = data.ownerispremium;
		var isOwnerBusiness = data.ownerisbusiness;

        if (typeof gtag === "function") {
            gtag("event", "shared_link_click", {
                action: "download",
                location: "header",
                type: "archive",
                user: HFN.config.auth ? 1 : 0
            })
        }

		if (isOwnerPremium) {
			HFN.initShowDownloadLinkModal();
		} else if (!isLogged && !isOwnerPremium && !isOwnerBusiness) {
			HFN.initDownloadZipLoginRegModal();
		}

		/*
		if (!HFN.config.auth && $.browser.android) {
			this.ads.showAd(this.data);
		}
		*/

		if ($.browser.mobile)
			location.href = self.data.downloadlink;
		else
			window.open(self.data.downloadlink);
	},

	_actionSheetDownloadItems: function(data) {
		var meta = data ? data : this.data.metadata;
		var actions = [];

		if (isSameLocation()) {
			actions.push({
				action: 'savetopcloud',
				title: __('save_to_pcloud', 'Save to pCloud'),
				subtext: __('save_to_pcloud_description'),
				icon: 'icon-save',
				active: true
			});
		}

		actions.push({
			action: 'download',
			title: meta.isfolder ? __('save_to_mob_device_zip', 'Save to Device as ZIP') : __('save_to_device', 'Save to Device'),
			subtext: __('save_to_device_zip_description'),
			icon: 'icon-dwl',
			active: true
		});

		return actions;
	},

	createDownloadButtonMobile: function (data) {
	  	if (!data) {
			data = this.data;
		}
		var self = this;
		var shareBtn = $('<a href="javascript:;" class="download"></a>');
		var meta = data.metadata;
		var shareBtn;

	 	actionSheet.setup({
		    el: shareBtn,
		    actionTitle: meta.isfolder && 0 ? __('save_folder_zip', 'Save folder as ZIP') : false,
		    items: this._actionSheetDownloadItems(),
		    onClick: function (item) {
					if (item.action == 'savetopcloud') {
						self._actionCopyToCloud();
					} else if (item.action == 'download' && item.active) {
						self._actionDownloadFile();
					}

					actionSheet.closeAll();
		    },
		    buildItem: function (item, obj) {
					// item.append($('<span class="title"></span>').text(obj.title));

					item.append(
						$('<div class="info-wrap"></div>')
							.append($('<div class="title"></div>').text(obj.title))
							.append($('<div class="sub-text"></div>').text(obj.subtext))
					)
					.addClass("download-mobile-sheet");

					if (!obj.isaction && !obj.iscancel) {
 						item.append($('<span class="sheet-icon sheet-icon-download"></span>').addClass(obj.icon));
					}

					if (obj.active != undefined && !obj.active) {
						item.addClass('inactive');
					}

					/*
					if (obj.action === "savetopcloud") {
						item.find(".title").remove();
						item.append(
							$("<a></a>")
								.addClass("title")
								.attr("href", "https://www.pcloud.com/app-save.html?code=" + self.code)
								.text(obj.title)
								.on("click", function(e) {
									//e.stopPropagation();
								})
							).on("click", function(e) {

							});
					}
					*/
				}
		});
		return shareBtn;
	},

	createUploadButtonMobile: function () {
		var self = this;
		var uploadBtn = $('<a href="javascript:;" class="upload"></a>');
		var data = self.data;
		uploadBtn.off('click').on('click', function() {
			handleDisabledInfoModals(HFN.uploadControl.uploadFileFromCustomButton);
		})
		return uploadBtn;
	},

	createShareButtonMobile: function () {
		var self = this;
		var shareButton = $('<a href="javascript:;" class="share"></a>');
		var items = [{
			act: 'copy',
			analytics: "copy",
			title: __('Copy link', 'Copy Link'),
			icon: 'icon-copy'
		}];

		if (navigator.userActivation && typeof navigator.share === 'function') {
			items.unshift({
				act: 'share',
				analytics: "share",
				title: __('Share', 'Share'),
				icon: 'icon-share'
			});
		} else {
			items.push({
				act: 'fb',
				analytics: "facebook",
				title: __('Share on Facebook', 'Share on Facebook'),
				icon: 'icon-fb'
			});
			items.push({
				act: 'tw',
				analytics: "twitter",
				title: __('Share on Twitter', 'Share on Twitter'),
				icon: 'icon-tw'
			});
		}

		actionSheet.setup({
			el: shareButton,
			onClick: function (item) {
				if (item.act === "share" && typeof gtag === "function") {
					gtag("event", "shared_link_click", {
						action: "initiate mobile share",
						category: "share",
						type: "mobile share",
						location: "header",
						user: HFN.config.auth ? 1 : 0
					})
				} else if (item.act === "copy" && typeof gtag === "function") {
					gtag("event", "shared_link_click", {
						action: "copy link",
						category: "share",
						type: "copy",
						location: "header",
						user: HFN.config.auth ? 1 : 0
					})
				} else {
					gtag("event", "shared_link_click", {
						action: `initaite ${item.analytics} share`,
						category: "share",
						type: "social share",
						location: "header",
						user: HFN.config.auth ? 1 : 0
					})
				}

				self.mobileShare(item.act);
			},
			items: items,
			buildItem: function (item, obj) {
				item.append($('<span class="title"></span>')
					.text(obj.title))
					.addClass("share-mobile-sheet");
				if (!obj.isaction && !obj.iscancel)
					item.append($('<span class="sheet-icon sheet-icon-share"></span>').addClass(obj.icon))
			}
		});

		return shareButton;
	},

	_location: function () {
      	return location.protocol + '//' + location.host + location.pathname + '?code=' + this.code;
	},

	desktopShare: function (act) {
		switch (act) {
			case 'mail':
				var cont=HFN.renderTemplate('.viewpublink', {puburl: this._location()});
				Popup.open(cont, { title: 'Download Link' });
				$('.modal a#copy-publink').zclip({
					path: '/swf/ZeroClipboard.swf',
					copy: $('.modal input[name=publinkurl]').val()
				});
			break;
			case 'cp':
			/*
			var cont=HFN.renderTemplate('.viewpublink', {puburl: publink.sharer.location()});
			Popup.open(cont, { title: 'Download Link' });
			$('.modal a#copy-publink').zclip({
			path: '/swf/ZeroClipboard.swf',
			copy: $('.modal input[name=publinkurl]').val()
			});
			*/
			break;
			case 'fb':
				var popup = window.open('https://www.facebook.com/sharer/sharer.php?u='+ encodeURIComponent(this._location()), 'fbshare', 'height=400,width=570');
				if (window.focus) {
					popup.focus();
				}

				console.log('share', 'https://www.facebook.com/sharer/sharer.php?u='+ encodeURIComponent(this._location()));
			break;
			case 'tw':
				var popup=window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(this._location()) + '&text=pCloud Download Link','twshare','height=400,width=570');
				if (window.focus) {
					popup.focus();
				}
			break;
		};
	},

	mobileShare: function (act) {
		var self = this;

		switch (act) {
			case 'copy':
				try {
					navigator.clipboard.writeText(location.href).then(
						() => {
							HFN.message(__('Link copied.'));
						},
						() => {
							throw new Error();
						}
					).catch(() => {
						this.mobileShareView(HFN.renderTemplate('#shareCopylink', {
							puburl: self._location()
						}));
					});
				} catch (err) {
					this.mobileShareView(HFN.renderTemplate('#shareCopylink', {
						puburl: self._location()
					}));
				}
			break;
			case 'fb':
				this.mobileShareView(
					HFN.renderTemplate('#shareFacebook', { puburl: self._location() })
						.find('.button')
						.on('click', function (e) {
							e.preventDefault();
							window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(self._location()));
						}).end()
				);
			break;
			case 'tw':
				this.mobileShareView(
					HFN.renderTemplate('#shareTwitter', { puburl: self._location() })
						.find('.button')
						.on('click', function (e) {
							e.preventDefault();
							window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(self._location()) + '&text=pCloud Download Link');
						}).end()
				);
			break;
			case 'share':
				if (navigator.userActivation.isActive && typeof navigator.share === 'function') {
					navigator.share({
						title: 'pCloud Download Link',
						text: (self.data.metadata.name != '') ? self.data.metadata.name : '',
						url: self._location(),
					})
					.then(() => '')
					.catch(() => '');
				}
				break;
		}
	},

	mobileShareView: function (content) {
		console.log('viewing', content);

		var self = this;
		var view;

		if ($('.mob-dialog').length)
			$('.mob-dialog').remove();

		view = $('<div class="mob-dialog"></div>').append(content);

        view.prepend($('div.header').clone().css('position', 'static'));
        view.find('.buttons').empty().append(
        	$('<a href="javascript:;" class="mob-close"></a>')
        		.on('click', function (e) {
        			self.mobileShareClose();
        			hashManager.removeState('sh');
        		})
        );
        view.css({ width: '100%', height: '200%', top: 0 });
        $('body').append(view);

        setTimeout(function () {
        	view.addClass('show');
        	$('html').addClass('noscroll');
        }, 0);
	},

	mobileShareClose: function () {
		$('.mob-dialog').remove();
		$('html').removeClass('noscroll');
		// Dummy change
	},

	imageUrl: function(width, height) {
		return HFN.apiMethodUrl(false, 'getpubthumb', {
			code: this.code,
			linkpassword: this.data.linkpassword,
			size: width + 'x' + height,
			crop: 0,
			type: 'auto'
		});
	},

	checkImageUrl: function(imageUrl, onSuccess, onError) {
		$.ajax({
			url: imageUrl,
			type: 'GET',
			dataType: 'json',
			processData: false,
			success: function({ error }) {
				if (error) {
					onError();
				} else {
					onSuccess();
				}
			},
			error: function() {
				onSuccess();
			}
		})
	},

	dynamicTitle: function (meta) {
		var template = HFN.renderTemplate('#dLinkDynamicTitle', {
			filename: meta.name,
			filesize: HFN.formatSize(meta.isfolder ? HFN.folderData(meta)[0] : meta.size)
		});

		var resize = function () {
			template.find('.filename').css('max-width', $(window).outerWidth() - template.find('.size').outerWidth() - 30);
		}

		setTimeout(resize, 0);

		return { template: template, resize: resize };
	},

	setFooterPosition: function(content) {
		var areaHeight = $(window).outerHeight();
		var contentHeight = $(content).outerHeight();
		var footerHeight = $('footer').outerHeight();

		console.log("setFooterPosition areaHeight", areaHeight)
		console.log("setFooterPosition contentHeight", contentHeight)
		console.log("setFooterPosition footerHeight", footerHeight)
	},

	centralizeDlContent: function(content) {

		// resets
		$(content).css('marginTop', 0);

		var contentHeight = $(content).outerHeight();
		var areaHeight = $(window).outerHeight();
		var headerHeight = $('.header-wrap').outerHeight();
		var titleHeight = $('._title').outerHeight();
		var footerHeight = $('footer').outerHeight();
		var minMTop = headerHeight + 10;
		var mtop;

		console.log('content', content);
		console.log('headerHeight', headerHeight);
		console.log('contentHeight', contentHeight);
		console.log('titleHeight', titleHeight);

		// to centeralize the video only
		// count the title two times
		//
		console.log(contentHeight);
		console.log(titleHeight);

		// contentHeight += titleHeight;

		console.log('contentHeight', contentHeight);

		// remove header
		//areaHeight -= headerHeight;

		console.log("areaHeight", areaHeight);
		console.log("contentHeight", contentHeight);

		var freeSpace = 0;
		if (this.data.branding) {
			var brandingHeight = $(".branding-header").outerHeight();

			freeSpace = areaHeight - contentHeight - footerHeight - brandingHeight;
		} else {
			freeSpace = areaHeight - contentHeight - footerHeight;
		}

		console.log("freeSpace", freeSpace);
		console.log("this.data.branding", this.data.branding);

		mtop = ((freeSpace) / 2) + (headerHeight / 2);
		mtop = Math.max(minMTop, mtop);

		$('#dl_content').addClass('centralized').css('marginTop', mtop);
	},

	inviteToLogin: function() {
		var self = this;

		if (this.shouldBotherUser()) {
			setTimeout(function() {
				setcookie('ref', 1139, 0);
				HFN.initSurpriseLoginRegModal('register', function() {
					self._actionCopyToCloud();
				});
			}, 3000);
		}
	},

	shouldBotherUser: function() {
		var data = this.data;

		if (rcookie('pcauth')) {
			return false;
		}

		if (data.ownerispremium && !data.showads) {
			return false;
		}

		if (data.ownerisbusiness) {
			return false;
		}

		return true;
	},

	initSidebar: function() {
		var self = this;

		if (this.data.ownerisme && HFN.config.isSite()) {

			if (!$(".dl-sidebar").length) {
				$("#megawrap > div").append('<div class="dl-sidebar minimized"></div>');
// to do:
				if(true) {
					$(".dl-sidebar").css({
						top: $(".branding-header").outerHeight() + $(".header-wrap").outerHeight()
					})
				}
			}

			/*
			$('.profile').before(
				$('<div class="dl-stats"></div>')
					.on("click", function() {
						if (self.removeSidebar) {
							self.closeSidebar();
						} else {
							self.openSidebar();
						}
					})
			);
			*/

			setTimeout(this.openSidebar.bind(this), 500);
		}
	},

	removeSidebar: false,
	statsSidebar: function(opened) {
		var self = this;

		self.removeSidebar = prc.DLStatsSidebar($('.dl-sidebar')[0], {
			open: opened,
			token: rcookie('pcauth'),
			linkid: this.data.linkid,
			onButtonClicked: opened ? this.closeSidebar.bind(this) : this.openSidebar.bind(this),
			onInviteUsers: function() {
				console.log(self);

				//HFN.sharePublink(this.data.metadata);
				location.href = URLMY+"#page=shares&tab=myshares-tab" + '&sharelink=' + self.data.linkid;
			}
		});
	},
	openSidebar: function() {
		var self = this;


		//$('html').addClass('sidebar-opened');

		this.statsSidebar(true);
		$(".dl-sidebar").removeClass("minimized");

		/*
		this.removeSidebar = prc.DLStatsSidebar($('.dl-sidebar')[0], {
			token: rcookie('pcauth'),
			linkid: this.data.linkid,
			onCloseRequest: this.closeSidebar.bind(this),
			onInviteUsers: function() {
				//HFN.sharePublink(this.data.metadata);
				location.href = "/#page=shares&tab=myshares-tab" + '&sharelink=' + self.data.linkid;
			}
		});
		*/

		//$(window).trigger('resize');
	},

	closeSidebar: function() {

		console.log("closing");

		this.statsSidebar(false);

		if (this.removeSidebar && 0) {
			this.removeSidebar();
			this.removeSidebar = false;
		}

		$(".dl-sidebar").addClass("minimized");
		//$('.dl-sidebar').remove();

	},

	getBrandingImageSize: function(img, prop) {
		return (this.brandingImagesSize[img.path] && this.brandingImagesSize[img.path][prop]) || null;
	}
}

function isVideo(view) {
	return ['video', 'folder'].indexOf(view) != -1;
}

function isAsia() {
	return (asiaCodes.indexOf(dLink.data.country.toUpperCase()) != -1 ||
		['XZ9kPuZ7UmbmhujfAFdFw41KksjUQEncHC7', 'XZTFiBZ0AGvm0aDhc7ahyC2qsw1QQ0DOUCy',
		 'XZ2e6uZvJw7eLxgI9muKXt5cD59vmXG5UaX', 'kZxUYXZO2KKG7hDh2FrjyJoV2lNAyItJxBk',
		 'XZK7guZ6PzAY9Li1q8oLxmsDLvPGpkV3rck', 'XZjQH2Z9oAJXbMpQT4mWbsxX1UnVVEPzBkV',
		 'XZG0nsZYALq95nK85B7iPCJS8mbBpkUz4yk', 'kZPSRBZbzYSrOcNujXXsc07r58R40crs4sy',
		 'XZB8AnZAb3z52cIdvJasHaXvg9QimaotUry', 'kZQCAmZfgodlRVqq5paWk7s0Cp2p7bFVdry',
		 'XZz4DDZ3ExbUEFmzAHGFwF4MgTwLjNiONTy' // infolinks
		 ].indexOf(dLink.code) != -1);
}

export default dLink;
